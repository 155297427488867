import { isUndefined } from "@/utils/helpers";
import { FIELDS_NAME, IFormik } from "./const";
import dayjs from "dayjs";

export const formToValueSerializer = (values: IFormik) => ({
  [FIELDS_NAME.PLOT]: values.plot.id,
  [FIELDS_NAME.ORDER_CULTIVAR]: values.order_cultivar,
  [FIELDS_NAME.CULTIVAR_GROUP]: values.cultivar_group.id ? [values.cultivar_group.id] : undefined,
  [FIELDS_NAME.IS_HP]: values.is_hp,
  [FIELDS_NAME.IS_OOS]: values.is_oos,
  [FIELDS_NAME.IS_PHITO]: values.is_phyto,
  [FIELDS_NAME.TRIALS_FROM]: values.is_oos && values.trials_from ? dayjs(values.trials_from).format("YYYY-MM-DD") : null,
  [FIELDS_NAME.TRIALS_TO]: values.is_oos && values.trials_to ? dayjs(values.trials_to).format("YYYY-MM-DD") : null,
  [FIELDS_NAME.DISEASES]: values.is_phyto && values.diseases ? values.diseases.map((el)=>el.id) : [], 
});

export const convertInitialValues = (item) => {
  if (!isUndefined(item))
    return {
      ...item,
      [FIELDS_NAME.IS_HP]: item.hp,
      [FIELDS_NAME.IS_OOS]: item.oos,
      [FIELDS_NAME.IS_PHITO]: item.phito,
      [FIELDS_NAME.ORDER_CULTIVAR]:
        item?.order_cultivar?.id,
      [FIELDS_NAME.REGION]: {
        ...item?.plot?.branch.region,
        label: item?.plot?.branch?.region?.name,
      },
      [FIELDS_NAME.BRANCH]: {
        ...item?.plot?.branch,
        label: item?.plot?.branch?.name,
      },
      [FIELDS_NAME.PLOT]: {
        ...item?.plot,
        label: item?.plot?.name,
      },
      [FIELDS_NAME.CULTIVAR_USAGE_TYPE]: {
        ...item?.cultivar_group[0]?.usage_type,
        label: item?.cultivar_group[0]?.usage_type?.name,
      },
      [FIELDS_NAME.CULTIVAR_GROUP]: {
        id: item?.cultivar_group[0]?.id,
        label: `${item?.cultivar_group[0]?.variant?.severity}`,
      },
      [FIELDS_NAME.TRIALS_FROM]: item.trials_from
        ? dayjs(item.trials_from)
        : null,
      [FIELDS_NAME.TRIALS_TO]: item.trials_to
        ? dayjs(item.trials_to)
        : null,
      [FIELDS_NAME.DISEASES]: item?.diseases?.length
        ? item.diseases
        : undefined
    };
};

export const convertInitialValuesFromDeseasesModal = (item) => {
  if (!isUndefined(item))
    return {
      [FIELDS_NAME.IS_HP]: item.is_hp,
      [FIELDS_NAME.IS_OOS]: item.is_oos,
      [FIELDS_NAME.IS_PHITO]: item.is_phyto,
      [FIELDS_NAME.ORDER_CULTIVAR]: item?.order_cultivar,
      [FIELDS_NAME.REGION]: item?.region ? {
        id: item?.region.id,
        label: item?.region?.name,
        name: item?.region?.name,
      } : undefined,
      [FIELDS_NAME.BRANCH]: item?.branch ? {
        id: item?.branch?.id,
        label: item?.branch?.name,
        name: item?.branch?.name,
      } : undefined,
      [FIELDS_NAME.PLOT]: item?.plot ? {
        id: item?.plot.id,
        label: item?.plot?.name,
        name: item?.plot?.name,
      } : undefined,
      [FIELDS_NAME.CULTIVAR_USAGE_TYPE]: item?.cultivar_usage_type ? {
        id: item?.cultivar_usage_type?.id,
        label: item?.cultivar_usage_type?.name,
        name: item?.cultivar_usage_type?.name,
      } : undefined,
      [FIELDS_NAME.CULTIVAR_GROUP]: item?.cultivar_group ? {
        id: item?.cultivar_group?.id,
        label: item?.cultivar_group?.label,
      } : undefined,
      [FIELDS_NAME.TRIALS_FROM]: item.trials_from
        ? dayjs(item.trials_from)
        : null,
      [FIELDS_NAME.TRIALS_TO]: item.trials_to
        ? dayjs(item.trials_to)
        : null,
      [FIELDS_NAME.DISEASES]: item?.diseases?.length ? item?.diseases : undefined,
    }
}