import React from "react";
import { FastField, Form, Formik, useFormikContext } from "formik";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Yup from "@/utils/yup";
import { ROUTES, ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import { TStatementType } from "@/api/types";
import { STATEMENT_TYPE } from "@/const";
import useToastContent, { TOAST_CONTENT_TYPE } from "@/hooks/useToastContent";
import { DateTimePicker } from "@mui/x-date-pickers";

const FIELD_NAME = {
  STATEMENT_TYPE: "statement_type",
  REGISTRATION_DATE: "registration_date",
} as const;
const validationSchema = Yup.object().shape({
  [FIELD_NAME.STATEMENT_TYPE]: Yup.string().required("Заявка не выбрана"),
  [FIELD_NAME.REGISTRATION_DATE]: Yup.string().required("Дата не выбрана"),
});

interface IFormik {
  [FIELD_NAME.STATEMENT_TYPE]: TStatementType | null;
  [FIELD_NAME.REGISTRATION_DATE]: string | null;
}

export const formToValue = (): IFormik => ({
  [FIELD_NAME.REGISTRATION_DATE]: null,
  [FIELD_NAME.STATEMENT_TYPE]: null,
});

export const STATEMET_TYPES_SUBMIT = {
  create: "create",
  copy: "copy",
};

interface IAddProposal {
  handleClose: () => void;
  modalProps: {
    onSubmitType: keyof typeof STATEMET_TYPES_SUBMIT;
    id?: number | string;
    title: string;
  };
}

/** Модалка добления заявки с выбором типа */
const AddStatementForm: React.FC<IAddProposal> = ({
  handleClose,
  modalProps,
}) => {
  const formik = useFormikContext<IFormik>();
  const navigate = useNavigate();

  const handleChangeDate = (value: Date) => {
    formik.setFieldValue(FIELD_NAME.REGISTRATION_DATE, value);
  };

  const handleCancel = () => {
    handleClose();
    navigate(ROUTES_PATHS.statements);
  };
  return (
    <Form onSubmit={formik.handleSubmit} noValidate>
      <Box>
        <Box p={3}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            fontSize={24}
            component="h2"
          >
            {modalProps.title}
          </Typography>
        </Box>

        <Divider />

        <Box
          padding={"24px 24px 8px 24px"}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
        >
          <Stack spacing={1} direction="row">
            <FastField name={FIELD_NAME.STATEMENT_TYPE}>
              {({ field, meta }) => (
                <FormControl error={meta.touched && !!meta.error}>
                  <RadioGroup
                    {...field}
                    aria-labelledby={FIELD_NAME.STATEMENT_TYPE}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        value={STATEMENT_TYPE.APPROVAL_STATEMENT}
                        control={<Radio color="secondary" />}
                        label="На допуск"
                      />
                      <FormControlLabel
                        value={STATEMENT_TYPE.PATENT_STATEMENT}
                        control={<Radio color="secondary" />}
                        label="На патент"
                      />
                    </Stack>
                  </RadioGroup>
                  <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                </FormControl>
              )}
            </FastField>
          </Stack>

          <FastField name={FIELD_NAME.REGISTRATION_DATE}>
            {({ field, meta }) => (
              <FormControl error={meta.touched && !!meta.error}>
                <DateTimePicker
                  name={field.name}
                  onChange={handleChangeDate}
                  value={field.value}
                  label="Дата регистрации"
                  format="DD.MM.YYYY HH:mm"
                  sx={{
                    marginBottom: "20px",
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      required: true,
                      error: meta.touched && !!meta.error,
                      helperText: meta.touched && meta.error,
                      onBlur: field.onBlur,
                    },
                  }}
                />
              </FormControl>
            )}
          </FastField>
        </Box>
        <Divider />
        <Box p={3} display="flex" justifyContent="space-between">
          <Button variant="text" color="red" onClick={handleCancel}>
            Отмена
          </Button>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary"
            disabled={formik.isSubmitting}
          >
            Продолжить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

const AddStatement: React.FC<IAddProposal> = ({ handleClose, modalProps }) => {
  const { api, toastStore } = useStores();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const { onSubmitType, id, title = "Добавить заявку" } = modalProps;
  const navigate = useNavigate();

  const create = (values, formikHelpers) => {
    api.statement
      .addStatement(values.statement_type)
      .then((res) => {
        handleClose();
        navigate(`${res.id}/${ROUTES.addStatement}`);
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const copy = (values, formikHelpers) => {
    if (!id) return console.error("[ERR] - не переданн ид для копированния!!!");
    formikHelpers.setSubmitting(true);
    api.statement
      .postCopyStatement(id, {
        type: values.statement_type,
        registration_date: dayjs(values.registration_date)
          .local()
          .toISOString(),
      })
      .then((res) => {
        handleClose();
        navigate(`${res.id}/${ROUTES.addStatement}`);
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const ON_SUBMIT_VARIANTS = {
    create,
    copy,
  };

  return (
    <Formik
      initialValues={formToValue()}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={ON_SUBMIT_VARIANTS[onSubmitType]}
      component={() => (
        <AddStatementForm
          handleClose={handleClose}
          modalProps={{ ...modalProps, title }}
        />
      )}
    />
  );
};

export default AddStatement;
