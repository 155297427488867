import React, { useState } from "react";
import { FastField, Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import { IProps, TForm, FIELD_NAME, HANDBOOK_ORDER_TYPE } from "../const";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { FILE_AND_IMAGE_ACCEPT, FILE_AND_IMAGE_SUBTITLE, IFile } from "@/components/FileDropzone/const";

import { DatePicker } from "@mui/x-date-pickers";
import FileDropzone from "@/components/FileDropzone";
import YearDatePicker from "@/components/Fields/YearDatePicker";

/**
 * Форма создания/редактирования приказа
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const OrderForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore, api } = useStores();
  const [isFileAdd, setIsFileAdd] = useState(true);
  const form = useFormikContext<TForm>();

  const title = modalProps.orderId ? "Редактировать запись" : "Добавить запись";

  /**
   * Метод выбора даты
   * @param value
   */
  const handleChangeDate = (value: Date) => {
    form.setFieldValue(FIELD_NAME.ORDER_DATE, value);
  };

  const handleChangeFiles = (files: IFile[]) => {
    if (files.length) {
      setIsFileAdd(true);
    } 
    void form.setFieldValue(FIELD_NAME.FILES, files);
  };

  const deleteMutation = (fileId: number) => {
    let promise;
    if (modalProps.orderId) {
      promise = api.regulatoryInfo.orderDeleteFile(modalProps.orderId, fileId);
    }
    return promise;
  };

  return (
    <>
      <Box p={3}>
        <Typography>Приказ</Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={FIELD_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Название'
                  fullWidth
                  size='small'
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[FIELD_NAME.NAME]}
                  disabled={meta.isSubmitting}
                  error={meta.touched && !!meta.error}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name={FIELD_NAME.NUMBER}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Номер'
                  fullWidth
                  size='small'
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[FIELD_NAME.NUMBER]}
                  disabled={meta.isSubmitting}
                  error={meta.touched && !!meta.error}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <FastField name={FIELD_NAME.ORDER_DATE}>
            {({ field, meta }) => (
                 <DatePicker
                   name={field.name}
                   value={field.value}
                   onChange={handleChangeDate}
                   label='Дата приказа'
                   slotProps={{
                     textField: {
                       size: "small",
                       error: meta.touched && !!meta.error,
                       helperText: meta.touched && meta.error,
                       onBlur: field.onBlur,
                       required: true,
                       fullWidth: true,
                     },
                   }}
                 />
            )}
          </FastField>

          <YearDatePicker
              name={FIELD_NAME.YEAR}
              label="Год испытаний"
              required
              fullWidth
            />

          <FastField name={FIELD_NAME.ORDER_TYPE}>
            {({ field }) => (
              <FormControl>
                <RadioGroup {...field}>
                  <Stack direction='column'>
                    <Box>
                      <FormControlLabel
                        value={HANDBOOK_ORDER_TYPE.cultivar}
                        control={<Radio color='secondary' />}
                        label='Места испытаний'
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        value={HANDBOOK_ORDER_TYPE.hp_grouping}
                        control={<Radio color='secondary' />}
                        label='Правила группировки'
                      />
                    </Box>
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </FastField>

          <Field name={FIELD_NAME.FILES}>
            {({ field, meta }) => (
              <FormControl>
                <FormLabel>
                  <Typography variant='p14Medium'>Документы</Typography>
                </FormLabel>
                <FileDropzone
                  name={field.name}
                  value={field.value}
                  isFileAdd={isFileAdd}
                  setValue={handleChangeFiles}
                  deleteMutation={deleteMutation}
                  accept={FILE_AND_IMAGE_ACCEPT}
                  subtitle={FILE_AND_IMAGE_SUBTITLE}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type='submit'
            disabled={form.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default OrderForm;
